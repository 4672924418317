/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const { smoothAnchorate } = require('smooth-anchorate')
const SmoothScroll = require('smooth-scroll')
const ReactGA = require('react-ga')

ReactGA.initialize('UA-135540555-1')

exports.onRouteUpdate = (state) => {
  SmoothScroll('a[href*="#"]')
  smoothAnchorate()
  // GA: track page view
  ReactGA.pageview(state.location.pathname)
}
